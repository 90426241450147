import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
import { toast } from "react-toastify";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const base_url_2 = "https://digi.pmpframe.com/"

export const get_background_img = createAsyncThunk("background", async (type) => {
  const body = new FormData();
  body.append("action", "list");
  body.append("type", type);
//   body.append("userid", user.userid);
  const response = await fetch(base_url + "api/image.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const delete_background_img = createAsyncThunk("delbackground", async (data) => {
    const body = new FormData();
    body.append("action", "delete");
    body.append("id",data?.id);
    body.append("type",data?.type);
    const response = await fetch(base_url + "api/image.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  });

const backgroundSlice = createSlice({
  name: "background",
  initialState: {
    background_list: [],
    background_loading: false,
    delete_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(get_background_img.pending, (state, action) => {
      state.background_loading = true;
    });
    builder.addCase(get_background_img.rejected, (state, action) => {
      state.background_loading = false;
    });
    builder.addCase(delete_background_img.pending, (state, action) => {
      state.delete_loading = true;
    });
    builder.addCase(delete_background_img.rejected, (state, action) => {
      state.delete_loading = false;
    });
    builder.addCase(delete_background_img.fulfilled, (state, action) => {
      state.delete_loading = false;
      const data = action.payload;
      if (data.success) {
        toast.success(data?.message)
      } else {
        toast.error(data?.message)
      }
      //   store.dispatch(getClaims());
    });

    builder.addCase(get_background_img.fulfilled, (state, action) => {
      const data = action.payload;
      state.background_loading = false;
      if (data.success) {
        state.background_list = data.data;
      } else {
        state.background_list = [];
      }
    });
  },
});

export default backgroundSlice.reducer;
