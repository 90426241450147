import React, { useEffect, useState } from "react";
import {
  AspectRatio,
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  Td,
  Text,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import CustomButton from "../../components/CustomButton";
import { useSelector } from "react-redux";
import CustomPopup from "../../components/CustomPopup";
import { FiTrash2 } from "react-icons/fi";
import TableView from "../../components/TableView";
import Loader from "../../components/Loader";
import store from "../../redux/store";
import { delete_video, get_video } from "../../redux/slice/VideoSlice";
import { useNavigate } from "react-router-dom";
import ImageModal from "../../components/ImageModal";

const Videos = () => {
  const { video_list, video_loading, delete_loading } = useSelector(
    (state) => state.video
  );

  const [id, setId] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();

  useEffect(() => {
    store.dispatch(get_video());
  }, []);

  const onDelete = async () => {
    await store.dispatch(delete_video({ id: id }));
    store.dispatch(get_video());
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Videos List</Heading>
        <CustomButton
          title={"Add Video"}
          onClick={() => navigate("/add-video")}
        />
      </Flex>
      <Flex gap={2} align={"center"}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Videos: {video_list?.length}
        </Text>
      </Flex>

      {video_loading && video_list.length === 0 ? (
        <Loader />
      ) : video_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "Title", "Video", "Actions"]}
          body={video_list.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1}</Td>
              <Td textAlign={"center"}>{v?.Title}</Td>
              <Td
                textAlign={"center"}
                cursor={"pointer"}
                onClick={() => {
                  onViewOpen();
                  setUrl(v?.Video_url);
                }}
              >
                <Center>
                  {/* <video
                  width={"100px"}
                    autoPlay={false}
                    loop
                    className="background-video"
                    muted={false}
                  >
                    <source src={v?.Video_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                  <img width={"100px"} src={v?.Image_url} alt={v?.Title} />
                </Center>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      setId(v?.id);
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}

      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? You want to delete Video"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
      <ImageModal
        vid={true}
        isOpen={isViewOpen}
        onClose={onViewClose}
        url={url}
      />
    </Container>
  );
};

export default Videos;
