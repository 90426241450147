import {
  Card,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiEdit3, FiEye, FiTrash2 } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import CustomToolTip from "../../components/CustomTooltip";
import CustomPopup from "../../components/CustomPopup";
import { provider_action } from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import CustomInput from "../../components/CustomInput";
import Loader from "../../components/Loader";
import store from "../../redux/store";
import { getProviders } from "../../redux/slice/providerSlice";
import { exportPDF } from "../../utils/utils";
import TableHeader from "../../components/TableHeader";

const PolicyProvider = () => {
  const navigate = useNavigate();
  const { provider_list, p_loading } = useSelector((state) => state.providers);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [search, setSearch] = useState("");
  const delete_pro = () => {
    const body = new FormData();
    body.append("action", "delete");
    body.append("id", id);
    provider_action(body, setLoading).then(() => onClose());
  };
  const update_pro = () => {
    if (name.length == 0) {
      toast.info("Policy provider name cannot be empty");
      return;
    }
    const body = new FormData();
    body.append("action", "update");
    body.append("provider", name);
    body.append("id", id);
    provider_action(body, setLoading).then(() => {
      store.dispatch(getProviders());
      setName("");
      setId("");
      setAdd(false);
      setEdit(false);
    });
  };
  const Add_pro = () => {
    if (name.length == 0) {
      toast.info("Policy provider name cannot be empty");
      return;
    }
    const body = new FormData();
    body.append("action", "create");
    body.append("provider", name);

    provider_action(body, setLoading).then(() => {
      setName("");
      setId("");
      setAdd(false);
      setEdit(false);
    });
  };
  useEffect(() => {
    store.dispatch(getProviders());
  }, []);

  const headers = [
    { label: "S.No.", key: "no" },
    { label: "Name", key: "name" },
  ];
  const data = provider_list.map((elt, i) => ({
    no: i + 1,
    name: elt.provider,
  }));

  const table_data = provider_list.map((elt, i) => [i + 1, elt.provider]);

  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: ["S.No.", "Name"],
      fileName: "provider-list",
      tableName: "Insugo Provider List",
    });
  };
  return (
    <Container h={"full"} w={"full"} maxW={"container.lg"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Policy Provider List</Heading>
        <CustomButton
          title={" Add Provider"}
          onClick={() => {
            navigate("/policy-provider/add-provider");
          }}
        />
      </Flex>
      {add && (
        <Center>
          <Card maxW={"lg"} flex={1} p={4}>
            <Flex align={"center"} justify={"space-between"}>
              <Heading fontSize={"xl"}>Add Policy Provider</Heading>
              <IoClose
                size={30}
                cursor={"pointer"}
                onClick={() => {
                  setAdd(false);
                  setName("");
                  setId("");
                }}
              />
            </Flex>
            <CustomInput
              label1={"Policy Provider Name"}
              onlyone
              placeholder1={"Enter Policy Provider Name"}
              value1={name}
              onChange1={(e) => setName(e.target.value)}
            />
            <Center mt={3}>
              <CustomButton
                title={"Submit"}
                loading={loading}
                onClick={Add_pro}
              />
            </Center>
          </Card>
        </Center>
      )}
      <Spacer h={3} />
      {/* <ExportTable onPdfClick={export_pdf} CSVdata={data} headers={headers} /> */}
      <TableHeader
        filter
        onPdfClick={export_pdf}
        CSVdata={data}
        headers={headers}
        value={search}
        onSerchChange={(e) => setSearch(e.target.value)}
      />
      <Spacer h={3} />
      {p_loading && provider_list.length === 0 ? (
        <Loader />
      ) : provider_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          maxW={"container.lg"}
          headData={["S.No.", "Name", "Type", "Action"]}
          body={provider_list
            ?.filter((item) => {
              return item.provider.toLowerCase().includes(search.toLowerCase());
            })
            .map((v, i) => (
              <Tr key={i}>
                <Td textAlign={"center"}>{i + 1}</Td>
                <Td textAlign={"center"}>{v.provider}</Td>
                <Td textAlign={"center"}>
                  <Flex gap={2} justify={"center"}>
                    {v.Health == 1 && "Health"}
                    {(v.Health == 1 && v.Motor == 1 && " , ") ||
                      (v.Health == 1 && v.Life == 1 && " , ") ||
                      (v.Health == 1 && v.Others == 1 && " , ")}
                    {v.Motor == 1 && "Motor"}
                    {(v.Motor == 1 && v.Life == 1 && " , ") ||
                      (v.Motor == 1 && v.Others == 1 && " , ")}
                    {v.Life == 1 && "Life"}
                    {v.Life == 1 && v.Others == 1 && " , "}
                    {v.Others == 1 && "Others"}
                  </Flex>
                </Td>
                <Td textAlign={"center"}>
                  <Flex gap={5} justify={"center"}>
                    <CustomToolTip
                      button={
                        <FiEdit3
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            navigate("/policy-provider/edit-provider", {
                              state: v,
                            });
                          }}
                        />
                      }
                      mess={"Edit Provider"}
                    />

                    <CustomToolTip
                      button={
                        <FiTrash2
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setId(v.id);
                            onOpen();
                          }}
                        />
                      }
                      mess={"Delete Provider"}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
        />
      )}
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={delete_pro}
        title={"Delete"}
        mess={"Are you sure? You want to delete provider"}
      />
    </Container>
  );
};

export default PolicyProvider;
