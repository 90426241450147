import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import store from "../../redux/store";
import { getEmployee_list } from "../../redux/slice/employeeSlice";
import { useSelector } from "react-redux";
import TableView from "../../components/TableView";
import Loader from "../../components/Loader";
import { FiEdit3, FiEye, FiTrash2 } from "react-icons/fi";
import CustomToolTip from "../../components/CustomTooltip";
import { employee_action } from "../../utils/apis";
import { toast } from "react-toastify";
import CustomPopup from "../../components/CustomPopup";

const EmployeeList = () => {
  const navigate = useNavigate();
  const { employee_list, e_loading } = useSelector((state) => state.employee);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);

  useEffect(() => {
    store.dispatch(getEmployee_list(user.userid));
  }, []);
  const delete_emp = () => {
    onClose();
    const body = new FormData();
    body.append("action", "delete");
    body.append("id", id);
    employee_action(body, setLoading).then((v) => {
      if (v.success) {
        toast.success(v.message);
        store.dispatch(getEmployee_list());
      } else {
        toast.error(v.message);
      }
    });
  };
  return (
    <Container h={"full"} w={"full"} maxW={"container"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Employee List</Heading>
        <CustomButton
          title={"Add Employee"}
          onClick={() => {
            navigate("/employee-list/add-employee");
          }}
        />
      </Flex>
      <Spacer h={3} />
      {/* {!e_loading && employee_list.length === 0 && (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      )} */}
      {e_loading && employee_list.length === 0 ? (
        <Loader />
      ) :
      employee_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) :
      (
        <TableView
          maxW={"container"}
          headData={[
            "S.No.",
            "username",
            "emp_id",
            "admin_type",
            "permissions",
            "Action",
          ]}
          body={employee_list.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1}</Td>
              <Td textAlign={"center"}>{v.username}</Td>
              <Td textAlign={"center"}>{v.emp_id}</Td>
              <Td textAlign={"center"}>{v.admin_type}</Td>
              <Td textAlign={"center"}>{v.permissions}</Td>
              <Td textAlign={"center"}>
                {user.userid == v.id || (
                  <Flex gap={2} alignSelf={"center"}>
                    <CustomToolTip
                      button={
                        <FiEdit3
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            navigate("/employee-list/edit-employee", {
                              state: v,
                            });
                          }}
                        />
                      }
                      mess={"Edit Employee"}
                    />
                    <CustomToolTip
                      button={
                        <FiTrash2
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setId(v.id);
                            onOpen();
                          }}
                        />
                      }
                      mess={"Delete Employee"}
                    />
                  </Flex>
                )}
              </Td>
            </Tr>
          ))}
        />
      )
      }
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={delete_emp}
        title={"Delete"}
        mess={"Are you sure? You want to delete employee"}
      />
    </Container>
  );
};

export default EmployeeList;
