import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Input,
  Spacer,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiEdit3, FiEye, FiTrash2 } from "react-icons/fi";
import { GrAddCircle } from "react-icons/gr";
import { MdFileDownloadDone } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import CustomToolTip from "../../components/CustomTooltip";
import CustomPopup from "../../components/CustomPopup";
import { policy_action } from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import CustomInput from "../../components/CustomInput";
import Loader from "../../components/Loader";
import store from "../../redux/store";
import { getPolicylist } from "../../redux/slice/policylistSlice";
import ExportTable from "../../components/ExportTable";
import { exportPDF } from "../../utils/utils";
import TableHeader from "../../components/TableHeader";

const PolicyList = () => {
  const navigate = useNavigate();
  const { policies_list, p_loading } = useSelector((state) => state.policies);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [search, setSearch] = useState("");
  const delete_pro = () => {
    const body = new FormData();
    body.append("action", "delete");
    body.append("id", id);
    policy_action(body, setLoading).then(() => onClose());
  };
  const update_pro = () => {
    if (name.length == 0) {
      toast.info("Policy provider name cannot be empty");
      return;
    }
    const body = new FormData();
    body.append("action", "update");
    body.append("name", name);
    body.append("id", id);
    policy_action(body, setLoading).then(() => {
      setName("");
      setId("");
      setAdd(false);
      setEdit(false);
    });
  };
  const Add_pro = () => {
    if (name.length == 0) {
      toast.info("Policy provider name cannot be empty");
      return;
    }
    const body = new FormData();
    body.append("action", "create");
    body.append("name", name);

    policy_action(body, setLoading).then(() => {
      setName("");
      setId("");
      setAdd(false);
      setEdit(false);
    });
  };
  useEffect(() => {
    store.dispatch(getPolicylist());
  }, []);

  const headers = [
    { label: "S.No.", key: "no" },
    { label: "Policy Name", key: "name" },
  ];
  const data = policies_list.map((elt, i) => ({
    no: i + 1,
    name: elt.policyname,
  }));

  const table_data = policies_list.map((elt, i) => [i + 1, elt.policyname]);

  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: ["S.No.", "Policy Name"],
      fileName: "policy-list",
      tableName: "Insugo Policy List",
    });
  };
  return (
    <Container h={"full"} w={"full"} maxW={"container.lg"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Policies List</Heading>
        <CustomButton
          title={"Add Policy"}
          // icon={<GrAddCircle color="#fff" />}
          onClick={() => {
            navigate("/policies-list/add-policy");
          }}
        />
      </Flex>

      {add && (
        <Center>
          <Card maxW={"lg"} flex={1} p={4}>
            <Flex align={"center"} justify={"space-between"}>
              <Heading fontSize={"xl"}>Add Policy</Heading>
              <IoClose
                size={30}
                cursor={"pointer"}
                onClick={() => {
                  setAdd(false);
                  setName("");
                  setId("");
                }}
              />
            </Flex>
            <CustomInput
              label1={"Policy Name"}
              onlyone
              placeholder1={"Enter Policy Name"}
              value1={name}
              onChange1={(e) => setName(e.target.value)}
            />
            <Center mt={3}>
              <CustomButton
                title={"Submit"}
                loading={loading}
                onClick={Add_pro}
              />
            </Center>
          </Card>
        </Center>
      )}
      <Spacer h={3} />
      <TableHeader
        placeholder={"Search Policy..."}
        filter
        onPdfClick={export_pdf}
        CSVdata={data}
        headers={headers}
        value={search}
        onSerchChange={(e) => setSearch(e.target.value)}
      />
      <Spacer h={3} />
      {p_loading && policies_list.length === 0 ? (
        <Loader />
      ) : policies_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          maxW={"container.lg"}
          headData={["S.No.", "Policy Name", "Action"]}
          body={policies_list
            ?.filter((item) => {
              return item.policyname
                .toLowerCase()
                .includes(search.toLowerCase());
            })
            .map((v, i) => (
              <Tr key={i}>
                <Td textAlign={"center"}>{i + 1}</Td>
                <Td textAlign={"center"}>
                  {edit && id === v.id ? (
                    <Input
                      value={name}
                      maxW={"xs"}
                      autoFocus
                      size={"sm"}
                      onChange={(e) => setName(e.target.value)}
                    />
                  ) : (
                    v.policyname
                  )}
                </Td>
                <Td textAlign={"center"}>
                  {edit && id === v.id ? (
                    <Flex gap={5} justify={"center"}>
                      <CustomToolTip
                        button={
                          <MdFileDownloadDone
                            size={20}
                            cursor={"pointer"}
                            onClick={update_pro}
                          />
                        }
                        mess={"Save Policy"}
                      />

                      <CustomToolTip
                        button={
                          <IoClose
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              setEdit(false);
                              setName("");
                              setId("");
                            }}
                          />
                        }
                        mess={"Cancel Policy"}
                      />
                    </Flex>
                  ) : (
                    <Flex gap={5} justify={"center"}>
                      <CustomToolTip
                        button={
                          <FiEdit3
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              setId(v.id);
                              setName(v.policyname);
                              setEdit(true);
                              setAdd(false);
                            }}
                          />
                        }
                        mess={"Edit Policy"}
                      />

                      <CustomToolTip
                        button={
                          <FiTrash2
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              setId(v.id);
                              onOpen();
                            }}
                          />
                        }
                        mess={"Delete Policy"}
                      />
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
        />
      )}
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        onClick={delete_pro}
        title={"Delete"}
        mess={"Are you sure? You want to delete policy"}
      />
    </Container>
  );
};

export default PolicyList;
